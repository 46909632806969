<template>
  <div>
    <portal to="title-page">
      <h1>Crear moneda</h1>
    </portal>
    <v-card
      class="mx-auto elevation-1"
    >
      <v-card-title >
        <v-icon
          large
          left
        >
          {{mdiPlus}}
        </v-icon>
        <span class="title font-weight-light">Datos</span>
        <v-btn small class="ml-auto" @click="$router.go(-1)"><v-icon>{{mdiArrowLeft}}</v-icon></v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-form
          ref="form"
          v-model="validForm"
          lazy-validation
        >
          <v-row>
            <v-col cols="12" sm="12" md="12">
              <v-text-field
                label="Idioma"
                :prepend-icon="mdiFlag"
                :value="language ? language.name : ''"
                dense
                readonly
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="12">
              <v-text-field
                v-model="currency.code"
                label="Codigo ISO 4217"
                :rules="[rules.required, rules.codeLenght, rules.alpha]"
                :prepend-icon="mdiLock"
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="12">
              <v-text-field
                v-model="currency.name"
                label="Nombre"
                :prepend-icon="mdiText"
                :rules="[rules.required]"
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="12">
              <v-text-field
                v-model="currency.exchangeRate"
                label="Tipo de cambio"
                :rules="[rules.required, rules.number]"
                :prepend-icon="mdiNumeric"
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="12" class="pt-5">
              <v-btn
                :disabled="!validForm"
                color="primary"
                class="mr-4"
                @click="validateForm"
              >
                Agregar
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
  import { mdiPlus, mdiArrowLeft, mdiFlag, mdiText, mdiClose, mdiNumeric, mdiLock } from '@mdi/js';
  import Language from "@/models/Language.js";
  import Currency from "@/models/Currency.js";
   export default {
    name: "CurrencyCreate",
    data() {
      return {
        mdiPlus:mdiPlus,
        mdiArrowLeft:mdiArrowLeft,
        mdiFlag:mdiFlag,
        mdiText:mdiText,
        mdiClose:mdiClose,
        mdiNumeric:mdiNumeric,
        mdiLock:mdiLock,
        ///////////////////////////////////////////////
        language:null,
        ///////////////////////////////////////////////
        languageModel: new Language,
        currencyModel: new Currency,
        ///////////////////////////////////////////////
        validForm:true,
        currencyLanguage:{
          name:null,
        },
        currency:{exchangeRate:null,code:null},
        snackbar:{
          show:false,
          color:'',
          message:''
        },
        rules:{
          required: v => !!v || 'El campo es obligatorio',
          codeLenght: v => v && v.length == 3 || 'El campo codigo debe contener 3 letras',
          number : v => {
            const pattern = /^[0-9]+([.][0-9]+)?$/
            return pattern.test(v) || 'Numero invalido'
          },
          alpha : v => {
            const pattern = /^[a-zA-Z]+?$/
            return pattern.test(v) || 'El codigo solo deben ser letras'
          },
        }
      }
    },
    created () {
      this.$store.commit('SET_LAYOUT', 'admin')
    },
    methods:{
      validateForm() {
        if (this.$refs.form.validate()) {
          let loading = this.$loading.show();
          //let data = {name:this.extra.name, status:'inactive', category:this.extra.category.name, categoryId:this.extra.category.id};
          //console.log(data);
          this.currency.status = 'inactive';
          this.currency.main = 0;
          if (this.currency.code.toLowerCase() == 'usd') {
            this.currency.main = 1;
            this.currency.status = 'active';
          }
          this.currencyModel.add(this.currency, this.language).then(() => {
            this.showSnackbarSuccess('La moneda se creo correctamente');
            loading.hide();
            this.$router.push({path:'/backend/admin/currencies'});
          })
          .catch( error=> {
            console.log(error);
            loading.hide();
            this.showSnackbarError('Error al crear la moneda');
          })
        }
      },
      showSnackbarSuccess(message) {
        this.snackbar.show = true;
        this.snackbar.message = message;
        this.snackbar.color = 'green';
      },
      showSnackbarError(message) {
        this.snackbar.show = true;
        this.snackbar.message = message;
        this.snackbar.color = 'red';
      },
    },
    mounted() {
      this.languageModel.getAll().onSnapshot(snap => {
        this.languages = {};
        snap.forEach( doc => {
          this.languages[doc.id] = doc.data();
        })
        this.language = this.languages.es;
      })
    },
  }
</script>